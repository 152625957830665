import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from 'components/Layout';
import Container from 'components/Container';

import logo from 'assets/images/abcmag_logo.png';

const IndexPage = () => {
  return (
    <Layout pageName="home">
      <Helmet>
        <title>Coming soon</title>
      </Helmet>
      <Container>
        <p className="logo">
          <img src={logo} alt="ABC Mag" />
        </p>
        <p>
          ABCDEFGHIJKLMNOPQRSTUVWXYZ MAGAZINE is an editorial platform showcasing POC creatives and their stories.
        </p>
        <h1>
          Launching late 2020.
        </h1>
        <p>A <a href="http://www.kingdomofheaven.com" title="Kingdom of heavem" rel="nofollow" target="_blank" className="blue-font">Kingdom of Heaven</a> Project - Media, Arts, Design - London, Shanghai, Hong Kong</p>
      </Container>
    </Layout>
  );
};

export default IndexPage;
